define("client-app-omnivise-web/mirage/factories/module", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    'badges_de-DE'(index) {
      return [`badge-de-DE-${index}`];
    },

    'badges_en-US'(index) {
      return [`badge-en-US-${index}`];
    },

    'description_de-DE'(index) {
      return `description de-DE ${index}`;
    },

    'description_en-US'(index) {
      return `description en-US ${index}`;
    },

    icon(index) {
      return `https://dene-dev-fra-portal-images.s3.eu-central-1.amazonaws.com/${index}`;
    },

    name(index) {
      return `Name ${index}`;
    },

    readOnly: true,

    description(index) {
      return `Description ${index}`;
    },

    withInformationDetail: (0, _emberCliMirage.trait)({
      afterCreate(module, server) {
        server.create('module-information-detail', {
          module
        });
      }

    }),
    withExternalView: (0, _emberCliMirage.trait)({
      afterCreate(module, server) {
        server.create('external-view', {
          module
        });
      }

    })
  });

  _exports.default = _default;
});