define("client-app-omnivise-web/mirage/factories/configured-widget", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    withConfiguredComponent: (0, _emberCliMirage.trait)({
      afterCreate(configuredWidget, server) {
        server.create('configured-component', {
          configuredWidget
        });
      }

    })
  });

  _exports.default = _default;
});