define("client-app-omnivise-web/components/visualization-component-renderer", ["exports", "@glimmer/component", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.isReady}}
    {{add-event-listener-to-element
      (create-element
        this.customElementName
        accessTokens=(json-stringify this.accessTokens)
        urlState=this.urlState
      )
      "urlStateChange"
      this.handleUrlStateChangeEvent
    }}
  {{else if this.isError}}
    <Alert @type="danger" @dismissible={{false}}>
      <:header>
        {{t "screen.internal-view.loading-failed.title"}}
      </:header>
      <:body>
        <p>
          {{t "screen.internal-view.loading-failed.explanation"}}
        </p>
      </:body>
    </Alert>
  {{else}}
    <Spinner @size="md" />
  {{/if}}
  */
  {
    "id": "USu1JWh8",
    "block": "{\"symbols\":[\"__arg0\"],\"statements\":[[6,[37,2],[[32,0,[\"isReady\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,6],[[30,[36,5],[[32,0,[\"customElementName\"]]],[[\"accessTokens\",\"urlState\"],[[30,[36,4],[[32,0,[\"accessTokens\"]]],null],[32,0,[\"urlState\"]]]]],\"urlStateChange\",[32,0,[\"handleUrlStateChangeEvent\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[32,0,[\"isError\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"alert\",[],[[\"@type\",\"@dismissible\",\"@namedBlocksInfo\"],[\"danger\",false,[30,[36,3],null,[[\"header\",\"body\"],[0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"header\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"screen.internal-view.loading-failed.title\"],null]],[2,\"\\n    \"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"body\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"p\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"screen.internal-view.loading-failed.explanation\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"spinner\",[],[[\"@size\"],[\"md\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"-is-named-block-invocation\",\"if\",\"hash\",\"json-stringify\",\"create-element\",\"add-event-listener-to-element\"]}",
    "moduleName": "client-app-omnivise-web/components/visualization-component-renderer.hbs"
  });

  const URL_STATE_MAX_LENGTH = 100;
  let VisualizationComponentRenderer = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._action, (_class = (_temp = class VisualizationComponentRenderer extends _component.default {
    /**
     * Tag name used for the custom element.
     */
    get customElementName() {
      const {
        componentDescriptor
      } = this.args.configuredComponent;
      const {
        id
      } = componentDescriptor.getProperties('id');
      return `visualization-component-${Ember.String.dasherize(id)}`;
    }

    get accessTokens() {
      const {
        componentDescriptor
      } = this.args.configuredComponent;
      const {
        authScopes
      } = componentDescriptor.getProperties('authScopes');
      const accessTokens = this.session.accessTokens;
      return authScopes.length > 0 ? Object.fromEntries(authScopes.map(scope => {
        return [scope, accessTokens[scope]];
      })) : null;
    }
    /**
     * URL State for this visualization component.
     *
     * The URL state might be either a string or undefined.
     */


    get urlState() {
      const {
        urlStateForAllConfiguredComponents
      } = this;
      const {
        configuredComponent
      } = this.args;
      const urlState = urlStateForAllConfiguredComponents[configuredComponent.id];
      return urlState;
    }

    set urlState(value) {
      const {
        configuredComponent
      } = this.args;
      const {
        urlStateForAllConfiguredComponents: oldState
      } = this;
      const componentId = configuredComponent.id;
      const newState = Object.assign({}, oldState); // The argument MUST be either a string representing the state or null to
      // reset the state.

      if (value === null) {
        delete newState[componentId];
      } else {
        newState[componentId] = value;
      }

      this.urlStateForAllConfiguredComponents = newState;
    }
    /**
     * The URL state for all configured components.
     *
     * It's persisted in the URL as query parameter `vcState`. The value of the
     * query parameter is a key-value map. The key is the ID of a configured
     * component. The value is the current URL state for that configured
     * component.
     *
     * The query paramter is registered on the `view` route.
     */


    get urlStateForAllConfiguredComponents() {
      const {
        viewRoute
      } = this;
      const {
        queryParams
      } = viewRoute;
      const {
        state: encodedUrlStates
      } = queryParams;
      return encodedUrlStates ? Object.freeze(JSON.parse(encodedUrlStates)) : {};
    }

    set urlStateForAllConfiguredComponents(value) {
      const state = Object.keys(value).length > 0 ? JSON.stringify(value) : undefined;
      this.router.transitionTo({
        queryParams: {
          state
        }
      });
    }

    handleUrlStateChangeEvent(event) {
      const {
        detail: value
      } = event;

      if (value && value.length > URL_STATE_MAX_LENGTH) {
        // TODO: replace with Sentry.captureMessage('A maximum length of urlState exceeded');

        /* eslint-disable no-console */
        console.error('A maximum length of urlState exceeded');
      } else {
        this.urlState = value;
      }
    }
    /**
     * `RouteInfo` for the `auth.view` route
     */


    get viewRoute() {
      const viewRoute = this.router.currentRoute.find(routeInfo => routeInfo.name === 'auth.view');
      (true && !(viewRoute) && Ember.assert('<VisualizationComponentRenderer /> component must only be used on view route', viewRoute));
      return viewRoute;
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "isError", _descriptor, this);

      _initializerDefineProperty(this, "isReady", _descriptor2, this);

      _initializerDefineProperty(this, "visualizationComponent", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "session", _descriptor5, this);

      this.prepare.perform();
    }
    /**
     * Execute all bootstraping tasks required before a visualization compoonent
     * defined by a component descriptor can be rendered.
     */


    *prepare() {
      // lazy-load visualization component
      yield (0, _emberConcurrency.all)([this.loadVisualizationComponent.perform(), this.ensureAccessTokensForAuthScopes.perform()]); // register it as a custom element

      this.registerVisualizationComponent();
      this.isReady = true;
    }

    *ensureAccessTokensForAuthScopes() {
      const {
        componentDescriptor
      } = this.args.configuredComponent;
      const {
        authScopes
      } = componentDescriptor.getProperties('authScopes');
      yield (0, _emberConcurrency.all)(authScopes.map(async authScope => {
        await this.session.ensureAccessTokenFor(authScope);
      }));
    }
    /**
     * Lazy-load a visualization component
     */


    *loadVisualizationComponent() {
      const {
        componentDescriptor
      } = this.args.configuredComponent;
      const {
        downloadUrl
      } = componentDescriptor.getProperties('downloadUrl');
      let ecmaScriptModule;

      try {
        if (Ember.testing) {
          // Ember CLI does not serve tests from root folder if run with
          // `ember serve`. We need to determine the folder name from
          // window.location in that case as it's dynamically changing.
          const folder = window.location.pathname.substr(0, window.location.pathname.indexOf('/tests'));
          ecmaScriptModule = yield import(`//${window.location.host}${folder}${downloadUrl}`);
        } else {
          ecmaScriptModule = yield import(`https://visualization-components.omnivise.siemens-energy.cloud${downloadUrl}`);
        }
      } catch (error) {
        // TODO: Distinguish between different errors to provide a better user
        //       experience. For example we may want to show a different message
        //       if it failed
        //       a) due to network connectivity issue or
        //       b) because the file referenced as `downloadUrl` does not exist.
        //       In the first case we may want to ask the user to check the
        //       internet connection. In the second case we may want to inform
        //       the user that something went wrong on our end, that the error
        //       has been locked and that he should retry later.
        this.isError = true; // TODO: Do not rethrow network connectivity errors as there is no value
        //       for us to log them.

        throw error;
      }

      const {
        default: visualizationComponent
      } = ecmaScriptModule;
      this.visualizationComponent = visualizationComponent;
    }
    /**
     * Register a visualization component as a custom element
     */


    registerVisualizationComponent() {
      const {
        customElementName,
        visualizationComponent
      } = this;
      (true && !(visualizationComponent) && Ember.assert('Visualization component must be loaded before it could be registered', visualizationComponent));
      const {
        customElements
      } = window; // check if the custom element is already defined

      if (customElements.get(customElementName) !== undefined) {
        (true && !(customElements.get(customElementName) === visualizationComponent) && Ember.assert('Another visualization component has already been registered with this name', customElements.get(customElementName) === visualizationComponent));
        return;
      } // register custom element


      customElements.define(customElementName, visualizationComponent);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isError", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isReady", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "visualizationComponent", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleUrlStateChangeEvent", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "handleUrlStateChangeEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "prepare", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "prepare"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ensureAccessTokensForAuthScopes", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "ensureAccessTokensForAuthScopes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadVisualizationComponent", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadVisualizationComponent"), _class.prototype)), _class));
  _exports.default = VisualizationComponentRenderer;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, VisualizationComponentRenderer);
});