define("client-app-omnivise-web/helpers/create-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CreateElementHelper extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "element", null);
    }

    compute([tagName = 'div'], attributes) {
      // initialize element on first computation
      if (this.element === null) {
        this.element = document.createElement(tagName);
      }

      const {
        element
      } = this;
      (true && !(tagName.toUpperCase() === element.tagName) && Ember.assert('Tag name passed to create element helper must not change over time.', tagName.toUpperCase() === element.tagName));

      for (const attribute in attributes) {
        const value = attributes[attribute]; // update attribute

        if (value === null || value === undefined || value === false) {
          // remove attribute if value is `null`, `undefined` or `false`
          if (element.hasAttribute(attribute)) {
            element.removeAttribute(attribute);
          }
        } else {
          // update attribute if needed
          if (value !== element.getAttribute(attribute)) {
            element.setAttribute(attribute, value);
          }
        }
      }

      return element;
    }

  }

  _exports.default = CreateElementHelper;
});