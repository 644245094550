define("client-app-omnivise-web/mirage/factories/component-descriptor", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    authScopes(index) {
      return [`auth-scope-${index}`];
    },

    downloadUrl(index) {
      return `https://fake-download/${index}`;
    },

    withConfiguredComponent: (0, _emberCliMirage.trait)({
      afterCreate(componentDescriptor, server) {
        server.create('configured-component', {
          componentDescriptor
        });
      }

    })
  });

  _exports.default = _default;
});