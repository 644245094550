define("client-app-omnivise-web/components/login/enroll-second-factor-question", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <p data-test-enroll-question-title>
    {{t "screen.login.mfa.set-question"}}
  </p>
  <Form
    @onSubmit={{this.enrollQuestionFactor}}
    data-test-enroll-question-form
    @model={{
      changeset (hash) (hash answer=(array (validate-presence true)))
    }} as |form|
  >
    <select class="w-100 mb-2 form-control" data-test-enroll-question-select>
      {{#each this.questions as |que|}}
        <option value={{que.question}} class="dropdown-item">
          {{que.questionText}}
        </option>
      {{/each}}
    </select>
    <form.element
      @property="answer"
      placeholder="answer"
      data-test-form-element-for="answer"
    />
  
    {{#if this.errorMessage}}
      <p class="text-danger" data-enroll-question-error-message>
        {{this.errorMessage}}
      </p>
    {{/if}}
    <form.submitButton data-test-enroll-question-submit as |button|>
      {{if button.isPending (t "form.button.submitting") (t "form.button.submit")
      }}
    </form.submitButton>
  </Form>
  */
  {
    "id": "SL3+6WJu",
    "block": "{\"symbols\":[\"form\",\"button\",\"que\"],\"statements\":[[10,\"p\"],[14,\"data-test-enroll-question-title\",\"\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"screen.login.mfa.set-question\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"form\",[[24,\"data-test-enroll-question-form\",\"\"]],[[\"@onSubmit\",\"@model\"],[[32,0,[\"enrollQuestionFactor\"]],[30,[36,4],[[30,[36,3],null,null],[30,[36,3],null,[[\"answer\"],[[30,[36,2],[[30,[36,1],[true],null]],null]]]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"select\"],[14,0,\"w-100 mb-2 form-control\"],[14,\"data-test-enroll-question-select\",\"\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,0,[\"questions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"option\"],[15,2,[32,3,[\"question\"]]],[14,0,\"dropdown-item\"],[12],[2,\"\\n        \"],[1,[32,3,[\"questionText\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[8,[32,1,[\"element\"]],[[24,\"placeholder\",\"answer\"],[24,\"data-test-form-element-for\",\"answer\"]],[[\"@property\"],[\"answer\"]],null],[2,\"\\n\\n\"],[6,[37,7],[[32,0,[\"errorMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"text-danger\"],[14,\"data-enroll-question-error-message\",\"\"],[12],[2,\"\\n      \"],[1,[32,0,[\"errorMessage\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[8,[32,1,[\"submitButton\"]],[[24,\"data-test-enroll-question-submit\",\"\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,7],[[32,2,[\"isPending\"]],[30,[36,0],[\"form.button.submitting\"],null],[30,[36,0],[\"form.button.submit\"],null]],null]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"validate-presence\",\"array\",\"hash\",\"changeset\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "client-app-omnivise-web/components/login/enroll-second-factor-question.hbs"
  });

  let EnrollSecondFactorQuestion = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class EnrollSecondFactorQuestion extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "questions", _descriptor, this);

      _initializerDefineProperty(this, "selectedQuestion", _descriptor2, this);

      _initializerDefineProperty(this, "errorMessage", _descriptor3, this);

      this.getQuestions();
    }

    async getQuestions() {
      this.questions = await this.args?.factor?.questions(); //set first question as defualt selected

      this.selectedQuestion = this.questions ? this.questions[0]['question'] : null;
    }

    async enrollQuestionFactor({
      answer
    }) {
      try {
        const transaction = await this.args.factor?.enroll({
          profile: {
            question: this.selectedQuestion,
            answer: answer
          }
        });
        this.args.onSecondFactorEnrollment(transaction);
      } catch ({
        message
      }) {
        this.errorMessage = message;
        throw new Error(message);
      }
    }

    onQuestionSelect(selectedQuestion) {
      this.selectedQuestion = selectedQuestion;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "questions", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedQuestion", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "enrollQuestionFactor", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "enrollQuestionFactor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onQuestionSelect", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onQuestionSelect"), _class.prototype)), _class));
  _exports.default = EnrollSecondFactorQuestion;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, EnrollSecondFactorQuestion);
});