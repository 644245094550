define("client-app-omnivise-web/helpers/add-event-listener-to-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addEventListenerToElement = addEventListenerToElement;
  _exports.default = void 0;

  function addEventListenerToElement([element, eventName, fn]) {
    (true && !(element instanceof HTMLElement) && Ember.assert('First argument must be an HTML element', element instanceof HTMLElement));
    (true && !(typeof eventName === 'string') && Ember.assert('Second argument must be an event name as a string', typeof eventName === 'string'));
    (true && !(typeof fn === 'function') && Ember.assert('Third argument must be a function used as event handler', typeof fn === 'function'));
    element.addEventListener(eventName, fn); // return element to make usage in template more ergonomic

    return element;
  }

  var _default = Ember.Helper.helper(addEventListenerToElement);

  _exports.default = _default;
});