define("client-app-omnivise-web/components/login/contact-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <p data-test-contact-us>
    <span class="text-secondary mr-2" data-test-contact-us-mail-icon>
      <Icon @icon="email" />
    </span>
    <a href="mailto:psdigops@mosaic.siemens.cloud" data-test-contact-us-mail-link>
      {{t "form.button.contact-support"}}
    </a>
  </p>
  */
  {
    "id": "bJRGWV1u",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[14,\"data-test-contact-us\",\"\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"text-secondary mr-2\"],[14,\"data-test-contact-us-mail-icon\",\"\"],[12],[2,\"\\n    \"],[8,\"icon\",[],[[\"@icon\"],[\"email\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"a\"],[14,6,\"mailto:psdigops@mosaic.siemens.cloud\"],[14,\"data-test-contact-us-mail-link\",\"\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"form.button.contact-support\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "client-app-omnivise-web/components/login/contact-support.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});