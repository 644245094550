define("client-app-omnivise-web/mirage/factories/module-information-detail", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    'benefits_de-DE'(index) {
      return [`benefit de-DE ${index}`];
    },

    'benefits_en-US'(index) {
      return [`benefit en-US ${index}`];
    },

    contactEmail(index) {
      return `contact-${index}@mail.com`;
    },

    contactFirstName(index) {
      return `FirstName${index}`;
    },

    contactLastName(index) {
      return `LastName${index}`;
    },

    contactPhoneNumber(index) {
      return `${index}-000000000`;
    },

    images(index) {
      return [`https://fake-image.com/${index}`];
    }

  });

  _exports.default = _default;
});