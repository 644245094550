define("client-app-omnivise-web/mirage/factories/internal-view", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    withConfiguredWidget: (0, _emberCliMirage.trait)({
      afterCreate(internalView, server) {
        server.create('configured-widget', {
          view: internalView
        });
      }

    })
  });

  _exports.default = _default;
});