define("client-app-omnivise-web/mirage/serializers/application", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.JSONAPISerializer.extend({
    keyForAttribute(attr) {
      return attr;
    },

    keyForRelationship(modelName) {
      return modelName;
    }

  });

  _exports.default = _default;
});