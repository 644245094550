define("client-app-omnivise-web/mirage/factories/external-view", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    url(index) {
      return `https://fake-url.com/${index}`;
    }

  });

  _exports.default = _default;
});