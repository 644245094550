define("client-app-omnivise-web/mirage/config", ["exports", "client-app-omnivise-web/config/environment", "client-app-omnivise-web/mirage/handlers/reset-password"], function (_exports, _environment, _resetPassword) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    const {
      host,
      namespace
    } = _environment.default.api;
    this.timing = 300; // delay for each request, automatically set to 0 during testing
    // These comments are here to help you get started. Feel free to delete them.

    /*
      Config (with defaults).
       Note: these only affect routes defined *after* them!
    */
    // this.urlPrefix = '';    // make this `http://localhost:8080`, for example, if your API is on a different server
    // this.namespace = '';    // make this `/api`, for example, if your API is namespaced
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing
    // this.logging = true;    // logs each request and its respective response on the console

    /*
      Shorthand cheatsheet:
       this.get('/posts');
      this.post('/posts');
      this.get('/posts/:id');
      this.put('/posts/:id'); // or this.patch
      this.del('/posts/:id');
       https://www.ember-cli-mirage.com/docs/route-handlers/shorthands
    */

    this.urlPrefix = `${host}/api/v1`;
    this.post('/apps/contact', {
      message: 'sucessfully posted'
    });
    this.post('/auth/reset-password', _resetPassword.default.post);
    this.urlPrefix = `${host}/${namespace}`;
    this.resource('modules');
    this.resource('module-information-details');
    this.resource('external-views');
    this.resource('internal-views');
    this.resource('configured-widgets');
    this.resource('configured-components');
    this.resource('component-descriptors', {
      only: ['index', 'show']
    });
  }
});