define("client-app-omnivise-web/mirage/handlers/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.post = void 0;

  const post = function (schema, request) {
    var params = JSON.parse(request.requestBody);

    if (params.email === 'show@error.com') {
      return new Response({
        errors: ['A bad thing happened']
      }, 500);
    } else {
      return {
        message: 'sucessfully posted'
      };
    }
  };

  _exports.post = post;
});