define("client-app-omnivise-web/components/login/enroll-second-factor-google-authenticator", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <p data-test-enroll-authentificator-title>
    {{t "screen.login.mfa.google-authenticator"}}
  </p>
  <Form
    @model={{
      changeset
      (hash)
      (hash passCode=(array (validate-presence true) (validate-number)))
    }}
    @onSubmit={{this.enrollGoogleFactor}}
    data-test-enroll-authentificator-form as |form|
  >
    <img
      src={{this.enroll.factor.activation.qrcode.href}}
      alt={{t "screen.login.mfa.qrcode-alt"}}
      class="mx-auto d-block my-2"
      data-test-enroll-authentificator-qrcode
    />
  
    <form.element
      @property="passCode"
      @placeholder="code"
      data-test-form-element-for="code"
    />
  
    {{#if this.errorMessage}}
      <h6 class="text-danger" data-enroll-authentificator-error-message>
        {{this.errorMessage}}
      </h6>
    {{/if}}
    <form.submitButton data-test-enroll-authentificator-submit as |button|>
      {{if button.isPending (t "form.button.submitting") (t "form.button.submit")
      }}
    </form.submitButton>
  </Form>
  */
  {
    "id": "Cf5jxto0",
    "block": "{\"symbols\":[\"form\",\"button\"],\"statements\":[[10,\"p\"],[14,\"data-test-enroll-authentificator-title\",\"\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"screen.login.mfa.google-authenticator\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"form\",[[24,\"data-test-enroll-authentificator-form\",\"\"]],[[\"@model\",\"@onSubmit\"],[[30,[36,5],[[30,[36,4],null,null],[30,[36,4],null,[[\"passCode\"],[[30,[36,3],[[30,[36,2],[true],null],[30,[36,1],null,null]],null]]]]],null],[32,0,[\"enrollGoogleFactor\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"img\"],[15,\"src\",[32,0,[\"enroll\",\"factor\",\"activation\",\"qrcode\",\"href\"]]],[15,\"alt\",[30,[36,0],[\"screen.login.mfa.qrcode-alt\"],null]],[14,0,\"mx-auto d-block my-2\"],[14,\"data-test-enroll-authentificator-qrcode\",\"\"],[12],[13],[2,\"\\n\\n  \"],[8,[32,1,[\"element\"]],[[24,\"data-test-form-element-for\",\"code\"]],[[\"@property\",\"@placeholder\"],[\"passCode\",\"code\"]],null],[2,\"\\n\\n\"],[6,[37,6],[[32,0,[\"errorMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h6\"],[14,0,\"text-danger\"],[14,\"data-enroll-authentificator-error-message\",\"\"],[12],[2,\"\\n      \"],[1,[32,0,[\"errorMessage\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[8,[32,1,[\"submitButton\"]],[[24,\"data-test-enroll-authentificator-submit\",\"\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,6],[[32,2,[\"isPending\"]],[30,[36,0],[\"form.button.submitting\"],null],[30,[36,0],[\"form.button.submit\"],null]],null]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"validate-number\",\"validate-presence\",\"array\",\"hash\",\"changeset\",\"if\"]}",
    "moduleName": "client-app-omnivise-web/components/login/enroll-second-factor-google-authenticator.hbs"
  });

  let EnrollSecondFactorGoogleAuthenticator = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = (_temp = class EnrollSecondFactorGoogleAuthenticator extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "enroll", _descriptor, this);

      _initializerDefineProperty(this, "errorMessage", _descriptor2, this);

      this.getEnroll();
    }
    /**
     * called to get the QR code from OKTA
     * it's called from init because QR code needs to be generated first
     */


    async getEnroll() {
      this.enroll = await this.args.factor?.enroll();
    }

    async enrollGoogleFactor({
      passCode
    }) {
      try {
        const transaction = await this.enroll?.activate({
          passCode
        });
        this.args.onSecondFactorEnrollment(transaction);
      } catch ({
        message
      }) {
        this.errorMessage = message;
        throw new Error(message);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "enroll", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "enrollGoogleFactor", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "enrollGoogleFactor"), _class.prototype)), _class));
  _exports.default = EnrollSecondFactorGoogleAuthenticator;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, EnrollSecondFactorGoogleAuthenticator);
});